.chooseProfileAndRemove {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 1.5rem;
}
.chooseProfileAndRemove h4{
    cursor: pointer;
}
.profileImg img {
    width: 222px;
    height: 222px;
    border-radius: 50%;
    object-fit: contain;
}

.otherInfoHead h3,
.dependentName h4 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.dependentName h4 {
    font-size: 20px;
    margin-bottom: 2rem;
}

.desclamerBg {
    border-radius: 30px;
    background: var(--Akos-Blue, #0562CC);
    display: flex;
    width: 23px;
    height: 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.socialHistoryLeft,
.emotionalHistoryBox,
.obgynHistoryBox {
    border-radius: 16px;
    border: 1.5px solid #EEE;
    background: var(--Akos-white, #FFF);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.familyHistoryLeft,
.familyHistoryRight {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.emotionalHistoryBox,
.obgynHistoryBox {
    padding: 8px 16px;
}

.emotionalSecFormGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row !important;
}

.justifyUnset {
    justify-content: unset !important;
}

.dontHaveAbha {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-weight: 400;
}
.dontHaveAbha span {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}