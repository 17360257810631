.loaderMain {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: 10;
}

.loaderAll {
    border: 5px solid var(--Akos-Blue);
    border-top: 5px solid #7699D0;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: loader 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.buttonLoaderAll {
    border: 3px solid var(--Akos-Blue);
    border-top: 3px solid #F0F4FA;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: loaderBtn 1s linear infinite;
    margin-right: 0.4rem;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loaderBtn {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}