.madicalRecordMain {
  margin-top: 1.5rem;
}

.medicalRecordUserSec {
  border-radius: 16px;
  border: 1.5px solid #eee;
  background: #fafbfc;
  padding: 16px 21px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.userNameAndImg {
  display: flex;
  gap: 10px;
}

.userImg {
  width: 50px;
}

.userImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.usercontent h3 {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 16px;
  font-weight: 700;
}

.usercontent p {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.userOtherData {
  text-align: center;
  min-width: 10%;
}

.fixMinWidth {
  min-width: 13%;
}

.userOtherData h4 {
  color: var(--Akos-Dark-grey, #767676);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.userOtherData p {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
}

.appointmentssection,
.fileSection {
  border-radius: 16px;
  border: 1.5px solid #eee;
  background: #fafbfc;
}

.fileSection {
  padding: 16px 10px;
}

.fileLists {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1.5rem;
}

.fileLists li {
  display: flex;
  gap: 1rem;
}

.fileLists li span {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 16px;
  font-weight: 400;
}

.doctorSec {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.doctorSec img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.typeOfCallBtn {
  border-radius: 200px;
  border: 1.5px solid var(--Akos-Blue, #0562cc);
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.StatusBtn {
  border-radius: 8px;
  border: 1.5px solid var(--mChemist-dark-green, #54a276);
  background: #c0cdd0;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--mChemist-dark-green, #54a276);
  font-size: 14px;
  font-weight: 700;
  display: inline-flex !important;
}

.StatusBtnCancel {
  border-radius: 8px;
  border: 1.5px solid #aa2626;
  background: #c8c0c8;
  color: #aa2626;
}

.StatusBtnUpcoming {
  background: #fff;
  border: 1.5px solid var(--Akos-Blue, #0562cc);
  color: var(--Akos-Blue, #0562cc);
}

.viewDetails p {
  color: var(--Akos-Black, #1a1a1a) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.completeTableRow th:first-child {
  width: 20%;
}

.completeTableRow th:last-child {
  width: 15%;
}

@media (max-width: 768px) {
  .medicalRecordUserSec {
    flex-direction: column;
    /* justify-content: center;
        align-items: center; */
  }
  .userOtherData {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .userOtherData p {
    font-weight: bold;
  }
}
