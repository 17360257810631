.bannerSection {
    border-radius: 8px;
    border: 1px solid #EEE;
    margin-top: 1.5rem;
}

.bannerSection img {
    width: 100%;
    height: auto;
}

.main {
    margin-top: 2rem;
}

.otherInfoHead h3 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.detailsMain {
    max-width: 80%;
    margin: auto;
    margin-top: 2rem;
}

.cardMain {
    border-radius: 16px;
    border: 1px solid #EEE;
    background: #FFF;
    display: inline-flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.cardMain h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}
.cardMain img{
    height: 150px;
}

.resultHead h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.progressbarScale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.progressbarScale h4 {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.progressBarDescription p {
    margin-top: 1rem;
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.summaryStyle {
    margin-top: 20px;
}

.summaryStyle p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
}

.summaryStyle h4 {
    font-weight: 700;
    color: #000;
    font-size: 16px;
}

@media (max-width: 768px) {
    .detailsMain{
        max-width: 100%;
    }
}