.assessmentsMain,
.HraResultMain {
    margin-top: 1.5rem;
}

.bannerSec {
    border-radius: 8px;
    border: 1px solid #EEE;
}

.bannerSec img {
    width: 100%;
    height: auto;
}

.AssessmentCard {
    border-radius: 8px;
    background: #FBFBFB;
    padding: 16px;
    padding-bottom: 0;
    padding-left: 8px;
}

.cardHead h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.imgAndContent {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.assessmentsImg {
    width: 60%;
}

.assessmentsImg img {
    height: 95px;
}

.assessmentsContent button,
.assessmentsContent button:focus,
.assessmentsContent button:active {
    padding: 5px 7px !important;
}

.assessmentsContent {
    text-align: center;
}

.assessmentsContent p {
    margin-top: 5px;
    cursor: pointer;
}

.assessmentsTakenByYou {
    margin-top: 2rem;
}

.sectionHead h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.HraCard {
    border-radius: 10.164px;
    background: #FFF;
    box-shadow: 0px 0px 3.495px 0px rgba(5, 98, 204, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.HraCard h3 {
    color: #2E2E30;
    font-size: 18px;
    font-weight: 700;
}

.HraCard p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.progressTextAndIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progressTextAndIcon p {
    color: #404040;
    font-size: 30px;
    font-weight: 700;
}

.hraIcon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ECEAF8;
}