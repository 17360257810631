.rpmManagementMain {
    margin-top: 1.5rem;
}

.doctorSec {
    display: flex;
    gap: 10px;
}

.readingBtn {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    width: 100%;
    height: 38px;
    color: var(--Akos-white, #FFF);
    font-size: 14px;
    font-weight: 700;
}


.warningBtn {
    background: #FFCD29;
}

.dangerBtn {
    background: #AA2626;
}

.normalBtn {
    background: var(--mChemist-dark-green, #54A276);
}

.connectText {
    color: var(--Akos-Dark-grey, #767676) !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.connectedText {
    color: var(--mChemist-dark-green, #54A276) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.rpmDetailsPageMain {
    margin-top: 1.5rem;
}

.sysDyaMain {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 4rem;
    margin-bottom: 1.5rem;
}

.sysContent {
    border-radius: 8px;
    background: var(--Akos-Blue, #0562CC);
    display: flex;
    width: 140px;
    padding: 10px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.sysContent h4 {
    color: var(--Akos-white, #FFF);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.sysContent p {
    color: var(--Akos-white, #FFF);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.notesHead h3 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.notesBox {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FAFBFC;
    padding: 16px;
}

.notesBox ul {
    list-style: disc;
    margin-left: 1.5rem;
}

.notesBox ul li{
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 400;
}