.main {
    margin-top: 1.5rem;
    width: 100%;
    background-color: #0562CC;
    border-radius: 6px;
    padding: 1.5rem;
}

.downloadAndWelcome {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.welcome h3 {
    color: var(--Akos-white, #FFF);
    font-size: 20px;
    font-weight: 600;
}

.welcome p {
    color: var(--Akos-white, #FFF);
    font-size: 26px;
}

.benefit h4 {
    color: var(--Akos-white, #FFF);
    font-size: 16px;
    font-weight: 700;
}

.benefitList li {
    color: var(--Akos-white, #FFF);
    font-size: 14px;
    font-weight: 400;
    list-style-type: disc;
    margin-left: 1.5rem;
    line-height: 26px;
}

.planCard {
    border-radius: 8px;
    background: #FFF;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.planCardTitle {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.planCardTitle h3 {
    color: var(--Akos-Blue, #0562CC);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    min-height: 40px;
}

.valueContainer {
    display: flex;
    align-items: flex-end;
    gap: 3px;
    justify-content: center;
    text-align: center;
}

.valueContainer h3 {
    color: var(--Akos-Blue, #0562CC);
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.valueContainer span {
    color: var(--Akos-Blue, #0562CC);
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    line-height: 14px;
}

.PatientCard {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 1.796px 28.279px 0px rgba(255, 255, 255, 0.25);
    padding: 12px;
}

.PImgId {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
}

.PImgId img,
.qrCode img {
    height: 117px;
}

.qrCode canvas {
    height: 117px !important;
    width: auto !important;
}

.PImgId p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.PImgId h3 {
    color: #000;
    font-size: 16px;
}

.PCardDetails {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 90%;
}

.PCardDetails h2 {
    color: #000;
    font-size: 17px;
    font-weight: 700;
}

.PCardDetails p,
.validity p {
    color: #000;
    font-size: 13px;
    font-weight: 600;
}

.validity {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PCardDetails p span,
.validity p span {
    font-size: 12px;
    font-weight: 400;
}

.uNameAndLogo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.logoImg {
    position: absolute;
    right: 0;
}

.logoImg img {
    height: 40px;
}

.contactAdminText {
    margin-top: 1.5rem;
}

.contactAdminText p {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 600;
}

.contactAdminText span {
    color: #AA2626;
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .image{
        height: 50px !important;
        object-fit: contain;
    }
    .PCardLeft{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}