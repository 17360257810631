.loginMain {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.formSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerSection {
    background: var(--Akos-Blue, #0562CC);
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.logo img {
    height: 100px;
    cursor: pointer;
}

.girlImg {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.girlImg img {
    max-height: 90vh;
}

.InputStyle {
    width: 455px;
}

.loginHead {
    margin-bottom: 1.5rem;
}

.loginHead h3 {
    font-size: 36px;
    font-weight: 700;
}

.loginHead h4 {
    font-size: 24px;
    font-weight: 600;
}

.loginHead p {
    font-size: 24px;
    font-weight: 400;
}

.forgotPassword {
    margin-top: 1rem;
    text-align: center;
}

.forgotPassword a {
    font-size: 14px;
    color: var(--Akos-Black, #1A1A1A);
    opacity: 0.7;
}

.resendOtp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.resendOtp h3 {
    font-size: 16px;
    font-weight: 600;
    color: var(--Akos-Black);
}

.resendOtpActive {
    cursor: pointer;
}

.resendOtpDisabled {
    opacity: 0.3;
}

.resendOtp p {
    font-size: 16px;
    font-weight: 400;
}

.showOnlyOnMobile {
    display: none;
    visibility: hidden;
}

@media screen and (max-width: 1200px) {
    .InputStyle {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .bannerSection {
        display: none;
    }

    .showOnlyOnMobile {
        display: unset;
        visibility: unset;
    }
    .loginMain{
        width: 100%;
    }

    .formSection {
        grid-column: span 7 / span 7 !important;
        height: calc(100vh - 100px);
    }
}