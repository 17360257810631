.generalDetailsSec {
  border-radius: 8px;
  border: 1px solid var(--Akos-Light-Grey, #c9c9c9);
  background: #fafbfc;
  padding: 11px 21px;
}

.generalDetailsMain,
.xRayHAndR {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.userImgAndName {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.userImgAndName .userImg img,
.docImg img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: contain;
}

.userDetails h3 {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 20px;
}

.userDetails p {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 16px;
  font-weight: 600;
}

.GOtherSec {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.GOtherSec h4 {
  color: var(--Akos-Dark-grey, #767676);
  font-size: 16px;
  font-weight: 600;
}

.GOtherSec p {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 16px;
  font-weight: 600;
  opacity: 1;
}

.rightSection {
  border-radius: 8px;
  border: 1px solid var(--Akos-Light-Grey, #c9c9c9);
  background: #fafbfc;
  padding: 11px;
}

.upcomingAppointmentsec {
  border-radius: 8px;
  background: var(--Akos-Blue, #0562cc);
  padding: 13px;
}

.UAHeadAndDot {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1.5px solid #fff;
  padding-bottom: 10px;
}

.UAHead h3 {
  color: var(--Akos-white, #fff);
  font-size: 18px;
  font-weight: 600;
}

.UAHead p {
  color: var(--Akos-white, #fff);
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
}

.docAndDateSec {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.docDate p {
  color: var(--Akos-white, #fff);
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
  opacity: 1;
}

.docNameSec {
  display: flex;
  gap: 10px;
}

.docName p {
  color: var(--Akos-white, #fff);
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
}

.ScoreTabSec {
  margin-top: 1rem;
}

.ScoreTab {
  border-radius: 8px;
  border: 2px solid var(--Akos-Black, #1a1a1a);
  background: var(--Akos-white, #fff);
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.ActiveScoreTab {
  background: var(--Akos-Blue, #0562cc);
}

.ScoreTab h4 {
  color: var(--Akos-Black, #1a1a1a);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.ScoreTab p {
  color: var(--Akos-Black, #1a1a1a);
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  opacity: 1;
}

.ActiveScoreTab h4,
.ActiveScoreTab p {
  color: #fff;
}

.AllSecHead h3 {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;
}

.careTeamCon {
  border-radius: 8px;
  border: 1px solid var(--Akos-Blue, #0562cc);
  background: var(--Akos-white, #fff);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.careTeamCon img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  object-fit: contain;
}

.careTeamCon p {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 14px;
  font-weight: 400;
}

.ALLergyCon {
  border-radius: 8px;
  background: rgba(37, 64, 107, 0.3);
  padding: 11px 16px;
  text-align: center;
}

.ALLergyCon p {
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
}

.NotesC p {
  font-size: 14px;
}

.reportSection {
  margin-top: 1rem;
}

.RRightMain {
  border-radius: 8px;
  border: 1px solid var(--Akos-Light-Grey, #c9c9c9);
  background: #fafbfc;
  padding: 8px;
}

.overAllRAndD {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.reportConMain {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reportCon,
.reportAlertConMain,
.reportXrayCon {
  border-radius: 8px;
  border: 1px solid #eee;
  background: var(--Akos-white, #fff);
  padding: 6px 8px;
}

.reportAlertConMain {
  border: 1px solid #fe8c4e;
}

.reportCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportAlertCon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.reportCon h5 {
  color: var(--mChemist-dark-green, #54a276);
  font-size: 12px;
  font-weight: 600;
}

.reportCon p {
  color: var(--Akos-Dark-grey, #767676);
  font-size: 12px;
  font-weight: 600;
}

.reportConDanger h5 {
  color: #aa2626;
}

.reportConDanger h5:nth-child(2) {
  font-weight: 700;
  font-size: 12px;
}

.reportAlertCon h5 {
  color: #fe8c4e;
  font-size: 12px;
  font-weight: 600;
}

.reportAlertCon h5:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
}

.reportAlertCon p {
  color: var(--Akos-Dark-grey, #767676);
  font-size: 12px;
  font-weight: 600;
}

.reportAlertConMain ul {
  list-style: disc;
  margin-left: 1.5rem;
  padding-top: 8px;
}

.reportAlertConMain ul li {
  color: var(--Akos-Black, #1a1a1a);
  font-size: 12px;
  font-weight: 600;
}

.reportXrayCon {
  display: flex;
  gap: 10px;
}

.xrayImg img {
  width: 74px;
  height: 74px;
  border-radius: 8px;
  object-fit: contain;
}

.xRayContentMain p {
  color: var(--Akos-Dark-grey, #767676);
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
}

.xRayHAndR h4 {
  color: var(--mChemist-dark-green, #54a276);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.riskSuggestMain {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.highRisk {
  display: flex;
  gap: 10px;
}

.highRisk,
.ModerateRisk,
.NormalRisk {
  display: flex;
  gap: 10px;
}

.highRisk .circle,
.ModerateRisk .circle,
.NormalRisk .circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.highRisk .circle {
  background-color: #aa2626;
}

.ModerateRisk .circle {
  background-color: #f9813e;
}

.NormalRisk .circle {
  background-color: #54a276;
}

.highRisk p,
.ModerateRisk p,
.NormalRisk p {
  font-size: 16px;
  font-weight: 600;
}

.highRisk p {
  color: #aa2626;
}

.ModerateRisk p {
  color: #f9813e;
}

.NormalRisk p {
  color: #54a276;
}

.RLeftBodyMain {
  position: relative;
  height: 100%;
}

.bodyImg {
  height: 100%;
}

.RLeftMain {
  position: relative;
}

.riskSuggestMain {
  position: absolute;
  z-index: 9;
}

.tooltipMain {
  min-width: 200px;
}

.tooltipHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltipHead h3 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.tooltipcontent p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ADchatNow {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.ADchatNow span {
  color: var(--Akos-white, #fff);
  font-size: 14px;
  font-weight: 400;
}

.APDDetailsMain {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.APDDetailsUserImg img {
  width: 49px;
  height: 49px;
  border-radius: 50%;
}

.APDDetailsContent h4 {
  color: var(--Akos-white, #fff);
  font-size: 12px;
  font-weight: 400;
}

.APDDetailsContent p {
  color: var(--Akos-white, #fff);
  font-size: 12px;
  font-weight: 600;
}
.APDUAHeadAndDot {
  align-items: center;
  border: none !important;
}

/* media query start */
@media (max-width: 1400px) {
  .generalDetailsSec,
  .rightSection,
  .upcomingAppointmentsec {
    padding: 8px;
  }
  .docAndDateSec,
  .docNameSec,
  .ScoreTabSec,
  .reportCon,
  .reportAlertCon,
  .reportXrayCon {
    flex-direction: column;
    align-items: flex-start;
  }
  .AllSecHead h3 {
    font-size: 16px;
  }
  .UAHead h3,
  .UAHead p,
  .docDate p,
  .docName p,
  .ScoreTab h4,
  .ScoreTab p,
  .reportCon h5,
  .reportCon p,
  .reportAlertCon h5,
  .reportAlertCon p,
  .ALLergyCon p,
  .userDetails h3,
  .GOtherSec h4,
  .GOtherSec p {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .generalDetailsSec,
  .rightSection,
  .upcomingAppointmentsec {
    padding: 6px;
  }
  .AllSecHead h3 {
    font-size: 14px;
  }
  .UAHead h3,
  .UAHead p,
  .docDate p,
  .docName p,
  .ScoreTab h4,
  .ScoreTab p,
  .reportCon h5,
  .reportCon p,
  .reportAlertCon h5,
  .reportAlertCon p,
  .ALLergyCon p,
  .userDetails h3,
  .GOtherSec h4,
  .GOtherSec p {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .generalDetailsMain,
  .xRayHAndR,
  .GOtherSec {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .userImgAndName,
  .overAllRAndD {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .riskSuggestMain {
    position: unset;
  }
}
/* media query end */
