.helpMain {
    margin-top: 1.5rem;
}

.contactSecBox {
    border-radius: 8px;
    border: 1.5px solid #EEE;
    background: var(--Akos-white, #FFF);
    padding: 20px 16px;
}

.contactText {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.didnotFind {
    color: var(--Akos-Blue, #0562CC);
    font-size: 16px;
    font-weight: 600;
}

.MailUsContactUs {
    text-align: center;
}

.MailUsContactUs p {
    color: var(--Akos-Blue, #0562CC);
    font-size: 17px;
    font-weight: 600;
}

.ThanksForChoose {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
}

.ThanksForChoose h4 {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}
.ThanksForChoose p {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}