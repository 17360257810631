.main {
    margin-top: 1rem;
}

.sidebarMain,
.bodyMain {
    border-radius: 16px;
    border: 1px solid var(--Primary-2-Primary-2200, #C3C6C6);
    background: #FBFBFB;
    height: calc(100vh - 188px);
    min-height: 400px;
}

.searchBox,
.sidebarHead {
    padding: 0 10px;
}

.sidebarHead h3 {
    color: var(--Accent-color-Accent-color200, #1D2225);
    font-size: 18px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 10px;
}

.userListMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(100% - 108px);
    overflow: auto;
    margin-top: 1rem;
    padding: 10px;
}

.userListMain::-webkit-scrollbar,
.bodyBox::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #EEE;
}

.userListMain::-webkit-scrollbar-thumb,
.bodyBox::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 4px;
}

.userImg,
.BHUIcon {
    width: 40px;
    position: relative;
}

.dot {
    position: absolute;
    bottom: 2px;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--Primary-2-Primary-2200, #C3C6C6);
}

.onlineDot {
    background: var(--Primary-color-Primary-color200, #0562CC);
}

.userListMain li,
.bHUser {
    display: flex;
    gap: 16px;
    align-items: center;
}

.userListMain li {
    cursor: pointer;
    padding: 5px 10px;
    position: relative;
}

.userName h4 {
    color: #1D2225;
    font-size: 16px;
    font-weight: 600;
}

.userName p {
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 400;
}

.bodyHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 16px 16px 0px 0px;
    border: 1px solid #DFE0EB;
    background: #FFF;
}

.bodyFooter,
.bodyBox {
    padding: 10px;
}

.bodyBox {
    height: 100%;
    overflow: auto;
}

.BHappointment {
    display: flex;
    gap: 10px;
    align-items: center;
}

.bookApBtn,
.bookApBtn:focus,
.bookApBtn:active {
    padding: 5px 10px !important;
    border-radius: 4px;
}

.bodyMain {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.chatDateMain {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.chatDate {
    border-radius: 4px;
    background: rgba(5, 98, 204, 0.15);
    padding: 8px 10px;
    text-align: center;
}

.ActiveUser {
    border-radius: 10px;
    border: 1.5px solid var(--Primary-color-Primary-color200, #0562CC);
    background: #FFF;
    box-shadow: 0px 0px 1.9px 0px rgba(0, 0, 0, 0.25);
}

.unReadMsg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--Primary-color-Primary-color200, #0562CC);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 5px;
}

.NoChat {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.NoChat h3 {
    color: #000;
    font-size: 32px;
    font-weight: 400;
}

.NoChat p {
    color: #000;
    font-size: 24px;
    font-weight: 400;
}

.fileIcon {
    height: 80px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .sidebarMain {
        height: auto;
        min-height: unset;
    }
}