.labTestMain,
.packageSections,
.cartMain,
.labtestDetails {
    margin-top: 2rem;
}

.BookLabbannerSection {
    border-radius: 16px;
    background: #D9D9D9;
    height: 197px;
    margin-top: 1.5rem;
}

.bannerSection{
    border-radius: 8px;
    margin-top: 1.5rem;
    border: 1px solid #EEE;
}
.bannerSection img{
    width: 100%;
    height: auto;
}

.uploadPreandCartBtn {
    display: flex;
    align-items: center;
    gap: 20px;
}

.SearchBoxMain {
    max-width: 50%;
    margin-top: 1.5rem;
}

.packagesMain {
    margin-top: 2rem;
}

.packageHead {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.packageCard {
    border-radius: 16px;
    border: 1px solid #EEE;
    background: var(--Akos-white, #FFF);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
}

.packageContent h3 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.packageContent h4 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.testNames {
    margin-top: 1rem;
}

.testNames p {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 14px;
    font-weight: 400;
}

.testNames h6 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.addMoreAndCount,
.addListsMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addMoreAndCount h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 700;
}

.addListsMain {
    margin-top: 1rem;
}

.addListsMain {
    align-items: flex-end;
}

.listLeft {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.testImg img {
    height: 56px;
}

.testsContent h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 700;
}

.testsContent p {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 14px;
    font-weight: 400;
}

.removeAll {
    display: flex;
    justify-content: flex-end;
}

.remove p,
.removeAll button {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 14px;
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
    text-align: right;
}

.checkBoxDiv {
    width: 20px;
    height: 20px;
    background: var(--Akos-Blue, #0562CC);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectLabTest {
    border-top: 1px solid #EEE;
    margin-top: 2rem;
}

.selectTestHead h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 700;
    padding-top: 1rem;
}

.cartRight {
    border-radius: 16px;
    border: 1px solid #EEE;
    background: #FAFBFC;
    padding: 16px;
}

.addressHead h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.addressCard {
    border-radius: 8px;
    background: #EEF0F3;
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.updateAddress p {
    text-align: right;
    color: var(--Akos-Dark-grey, #767676);
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    margin-top: 5px;
    cursor: pointer;
}

.addressCard h4 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.addressCard p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.selectDateTime,
.parameter {
    margin-top: 1rem;
}

.BCalenderHead h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
}

.availableAppointment {
    border-top: 1px solid #C9C9C9;
}

.availableApBox {
    border-radius: 8px;
    border: 1px solid var(--Akos-Black, #1A1A1A);
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.availableApBox p {
    font-size: 14px;
}

.selectedTime {
    background: var(--Akos-Blue, #0562CC);
}

.testContent h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.testContent p {
    font-size: 14px;
    font-weight: 400;
}

.selectedTime p {
    color: var(--Akos-white, #FFF);
}

.parameterHead h4 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.sampleColAndAddToCart {
    display: flex;
    align-items: center;
    gap: 16px;
}

.sampleCollection {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: rgba(37, 64, 107, 0.12);
    display: flex;
    width: 228px;
    height: 55px;
    padding: 12px 13px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
}

.sampleCollection h4 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-weight: 600;
}

.sampleCollection p {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-weight: 400;
}

.requirementtestBox {
    border-radius: 16px;
    border: 1.5px solid #EEE;
    background: #FFF;
    display: flex;
    padding: 26px 17px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    margin-top: 50px;
    min-height: 300px;
}

.requirementtestBox h4 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 18px;
    font-weight: 700;
}

.requirementtestBox p {
    font-size: 16px;
    font-weight: 400;
}

.LabLists {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid var(--border, #EFF5FF);
    background: var(--bg, #F6F9FE);
    padding: 12px;
}

.labSelectBox h3 {
    color: #1D2225;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.labSelectBox p {
    color: #1D2225;
    font-size: 16px;
    font-weight: 400;
}

.labBranchMain {
    margin-left: 28px;
}

.selectedPreBox {
    background-color: #F6F9FE;
    width: 100%;
    height: 200px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedPreBox img {
    height: 100px;
}

.presListMain {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.presListMain li {
    position: relative;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    cursor: pointer;
}

.selectList {
    border: 1px solid var(--Akos-Blue) !important;
}

.presListMain li span {
    position: absolute;
    top: -11px;
    right: -11px;
}

.ThisIsHomeTest h4 {
    font-size: 16px;
    margin-top: 1.5rem;
}

.makePayHead h3 {
    color: #1D2225;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.priceSectionMain {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 1.5rem;
}

.priceSectionMain li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.priceSectionMain li h3 {
    color: #1D2225;
    font-size: 16px;
    font-weight: 600;
}
.priceSectionMain li p{
    font-size: 16px;
}
.totalPriceS{
    border-top: 1px solid #EEE;
    padding-top: 12px;
}
.sBookingAdsec{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.sBookingAdsec h3{
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 600;
} 
.sBookingAdsec h3 span{
    color: var(--Akos-Dark-grey, #767676);
    font-size: 14px;
    font-weight: 400;
}
.SLListsMain{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.sPriceSec p{
    font-size: 16px;

}

.isFreeS p{
    font-size: 16px;
}
.otherInfoHead h3 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 2rem;
}